import type { Email, Role } from "@internal/database";
import type { Axios, AxiosResponse } from "axios";

import queryString from "query-string";

import type { Command } from "../type";

export interface GetMeCommandInput {
  birth?: boolean;
  email?: boolean;
  generation?: boolean;
  name?: boolean;
  phoneNumber?: boolean;
  role?: boolean;
}
export interface GetMeCommandOutput {
  birth: string;
  email: Pick<Email, "email" | "verified">;
  generation: number;
  name: string;
  phoneNumber: string;
  role: Role;
  uniqueId: string;
}
export class GetMeCommand implements Command<GetMeCommandOutput> {
  constructor(private readonly commandOptions: GetMeCommandInput) {}
  async execute(httpClient: Axios): Promise<GetMeCommandOutput> {
    const query = queryString.stringify(this.commandOptions, {
      arrayFormat: "comma",
    });
    const response: AxiosResponse = await httpClient.get(`/user/me?${query}`);
    return response.data;
  }
}
