import revive_payload_client_YGcc6zr7k7 from "/app/node_modules/.pnpm/nuxt@3.10.3_@parcel+watcher@2.4.0_@types+node@20.11.19_encoding@0.1.13_eslint@8.57.0_optionat_3uzx6d3zf4tmbezsq2f7x6z2ya/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_To6x9ZoYZ5 from "/app/node_modules/.pnpm/nuxt@3.10.3_@parcel+watcher@2.4.0_@types+node@20.11.19_encoding@0.1.13_eslint@8.57.0_optionat_3uzx6d3zf4tmbezsq2f7x6z2ya/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_fm3aXUrg6a from "/app/node_modules/.pnpm/nuxt@3.10.3_@parcel+watcher@2.4.0_@types+node@20.11.19_encoding@0.1.13_eslint@8.57.0_optionat_3uzx6d3zf4tmbezsq2f7x6z2ya/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_unX0ymsS9f from "/app/node_modules/.pnpm/nuxt@3.10.3_@parcel+watcher@2.4.0_@types+node@20.11.19_encoding@0.1.13_eslint@8.57.0_optionat_3uzx6d3zf4tmbezsq2f7x6z2ya/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_N7LYeqp8Lb from "/app/node_modules/.pnpm/nuxt@3.10.3_@parcel+watcher@2.4.0_@types+node@20.11.19_encoding@0.1.13_eslint@8.57.0_optionat_3uzx6d3zf4tmbezsq2f7x6z2ya/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_qL9IPgUs7G from "/app/node_modules/.pnpm/@pinia+nuxt@0.5.1_rollup@4.9.0_typescript@5.4.3_vue@3.4.19_typescript@5.4.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/apps/web/.nuxt/components.plugin.mjs";
import prefetch_client_btpS8t3XEw from "/app/node_modules/.pnpm/nuxt@3.10.3_@parcel+watcher@2.4.0_@types+node@20.11.19_encoding@0.1.13_eslint@8.57.0_optionat_3uzx6d3zf4tmbezsq2f7x6z2ya/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_nwnRxhhnGL from "/app/node_modules/.pnpm/@nuxtjs+plausible@0.2.4_rollup@4.9.0/node_modules/@nuxtjs/plausible/dist/runtime/plugin.client.mjs";
import chunk_reload_client_jhOu3uorbD from "/app/node_modules/.pnpm/nuxt@3.10.3_@parcel+watcher@2.4.0_@types+node@20.11.19_encoding@0.1.13_eslint@8.57.0_optionat_3uzx6d3zf4tmbezsq2f7x6z2ya/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import server_api_xr0Ky6u54N from "/app/apps/web/src/plugins/server-api.ts";
import dayjs_NBCB9zH19f from "/app/apps/web/src/plugins/dayjs.ts";
import pagination_client_mzaElNr7rV from "/app/apps/web/src/plugins/pagination.client.ts";
import pinia_persistedstate_client_Qj65jVIQJR from "/app/apps/web/src/plugins/pinia-persistedstate.client.ts";
import account_BmOH349oZJ from "/app/apps/web/src/plugins/account.ts";
import maska_EdO7pVjy2z from "/app/apps/web/src/plugins/maska.ts";
import sentry_client_KAXFuL2wum from "/app/apps/web/src/plugins/sentry.client.ts";
import vue3_toastify_client_sNkMxBXdsV from "/app/apps/web/src/plugins/vue3-toastify.client.ts";
export default [
  revive_payload_client_YGcc6zr7k7,
  unhead_To6x9ZoYZ5,
  router_fm3aXUrg6a,
  payload_client_unX0ymsS9f,
  check_outdated_build_client_N7LYeqp8Lb,
  plugin_vue3_qL9IPgUs7G,
  components_plugin_KR1HBZs4kY,
  prefetch_client_btpS8t3XEw,
  plugin_client_nwnRxhhnGL,
  chunk_reload_client_jhOu3uorbD,
  server_api_xr0Ky6u54N,
  dayjs_NBCB9zH19f,
  pagination_client_mzaElNr7rV,
  pinia_persistedstate_client_Qj65jVIQJR,
  account_BmOH349oZJ,
  maska_EdO7pVjy2z,
  sentry_client_KAXFuL2wum,
  vue3_toastify_client_sNkMxBXdsV
]