import type { Axios, AxiosResponse } from "axios";

import type { Command } from "../type/command";
export interface SignInCommandInput {
  id: string;
  password: string;
  requiredAdminRole?: boolean;
}
export interface SignInCommandOutput {
  accessToken: string;
}
const defaultValue: Pick<SignInCommandInput, "requiredAdminRole"> = {
  requiredAdminRole: false,
};
export class SignInCommand implements Command<SignInCommandOutput> {
  constructor(private readonly input: SignInCommandInput) {
    this.input = Object.assign({}, defaultValue, input);
  }
  async execute(axios: Axios): Promise<SignInCommandOutput> {
    const response: AxiosResponse = await axios.post("/auth/login", this.input);
    const result: SignInCommandOutput = { accessToken: response.data };
    return result;
  }
}
