import { useAccountStore } from "../stores/account";

export default defineNuxtPlugin(async (nuxtApp) => {
  const accountStore = useAccountStore(nuxtApp.vueApp.$nuxt.$pinia);
  if (process.server) {
    try {
      await accountStore.fetch();
    } catch (error) {}
  } else if (process.client && accountStore.accessToken?.length > 0) {
    // 서버에서 발급한 엑세스 토큰을 설정함
    // 이러면 자동 로그인 후 클라이언트 사이드에서도 API 호출 가능
    nuxtApp.vueApp.$nuxt.$api.accessToken = accountStore.accessToken;
  }
});
