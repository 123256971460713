<script setup lang="ts">
import type { NuxtError } from "#app";

import { Button } from "@internal/ui";
import ExclamationQuestionMark from "@tossface/u2049.svg?component";

const props = defineProps({
  error: Object as () => NuxtError,
});
const handleError = () => clearError({ redirect: "/" });
</script>

<template>
  <div class="container">
    <ExclamationQuestionMark style="width: 96px" />
    <p>{{ props.error.message }}</p>
    <p style="color: #888888">
      오류가 반복되는 경우 하단 연락처로 연락해 주세요.
    </p>
    <Button
      @click="handleError"
      class="redirect-button"
      label="메인 페이지로"
    />
  </div>
</template>
<style lang="scss" scoped>
.container {
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.redirect-button {
  width: 150px;
  margin-top: 16px;
}
</style>
