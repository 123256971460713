import type { Axios } from "axios";

import type { Command } from "../type";

export interface ChangePasswordCommandInput {
  newPassword: string;
  password: string;
}
export class ChangePasswordCommand implements Command<void> {
  constructor(private readonly commandOptions: ChangePasswordCommandInput) {}
  async execute(httpClient: Axios): Promise<void> {
    await httpClient.post("/user/change-password", this.commandOptions);
  }
}
