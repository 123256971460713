import { InternalAPIClient } from "@internal/server-api";
export default defineNuxtPlugin((nuxtApp) => {
  const internalAPIClient = new InternalAPIClient(
    `${nuxtApp.$config.public.apiUrl}/s-api`,
    () => {
      navigateTo({
        path: "/account/login",
        query: {
          logout: "true",
        },
      });
      const accountStore = useAccountStore(nuxtApp.vueApp.$nuxt.$pinia);
      accountStore.loggedIn = false;
    },
  );
  return {
    provide: {
      api: internalAPIClient,
    },
  };
});
