<script setup lang="ts">
const route = useRoute();
const runtimeConfig = useRuntimeConfig();
useHead(() => ({
  link: [
    {
      href: runtimeConfig.public.apiUrl + route.path,
      rel: "canonical",
    },
  ],
}));
</script>
<template>
  <NuxtLayout>
    <NuxtLoadingIndicator />
    <NuxtPage />
  </NuxtLayout>
</template>
