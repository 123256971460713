import type { Axios, AxiosResponse } from "axios";

import type { Command } from "../type";
export class RefreshTokenCommand implements Command<string> {
  async execute(
    axios: Axios,
    headers: Record<string, string> | null = null,
  ): Promise<string> {
    const newHeaders = Object.assign({}, headers);
    const response: AxiosResponse = await axios.post(
      "/auth/refresh",
      undefined,
      {
        headers: newHeaders,
      },
    );
    return response.data;
  }
}
