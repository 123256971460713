import Vue3Toastify, { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(Vue3Toastify, {
    autoClose: 1500,
    multiple: false,
    position: "bottom-center",
    progressStyle: {
      "background-color": "#007aff",
    },
    theme: "dark",
    transition: "slide",
    type: "default",
  });

  return {
    provide: { toast },
  };
});
