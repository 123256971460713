import { default as mapZ94bEYnZTJMeta } from "/app/apps/web/src/pages/about/map.vue?macro=true";
import { default as officerta841nHNpcMeta } from "/app/apps/web/src/pages/about/officer.vue?macro=true";
import { default as indexuaTi28IIdtMeta } from "/app/apps/web/src/pages/account/[menu]/index.vue?macro=true";
import { default as find_45id5nekwt9Lv7Meta } from "/app/apps/web/src/pages/account/find-id.vue?macro=true";
import { default as loginqZfjKEwutUMeta } from "/app/apps/web/src/pages/account/login.vue?macro=true";
import { default as reset_45password0FFhsJPtSbMeta } from "/app/apps/web/src/pages/account/reset-password.vue?macro=true";
import { default as indexZp9TuvxS0nMeta } from "/app/apps/web/src/pages/board/[board_id]/index.vue?macro=true";
import { default as index5jthpkw0hNMeta } from "/app/apps/web/src/pages/board/[board_id]/post/[post_id]/index.vue?macro=true";
import { default as modifycnVBNYFUb8Meta } from "/app/apps/web/src/pages/board/[board_id]/post/[post_id]/modify.vue?macro=true";
import { default as writebDhutlArMDMeta } from "/app/apps/web/src/pages/board/[board_id]/write.vue?macro=true";
import { default as _91token_934TlhMLrZN8Meta } from "/app/apps/web/src/pages/email/verify/[token].vue?macro=true";
import { default as indexFrDg441YV4Meta } from "/app/apps/web/src/pages/index.vue?macro=true";
import { default as _91uid_93WPbIUQonPWMeta } from "/app/apps/web/src/pages/notification/[uid].vue?macro=true";
import { default as privacyBK7OrfsIN8Meta } from "/app/apps/web/src/pages/privacy.vue?macro=true";
import { default as agreeNcP82S87eZMeta } from "/app/apps/web/src/pages/register/agree.vue?macro=true";
import { default as sign_45upTJ5nDt0GbZMeta } from "/app/apps/web/src/pages/register/sign-up.vue?macro=true";
import { default as successrRJMnPK5yHMeta } from "/app/apps/web/src/pages/register/success.vue?macro=true";
export default [
  {
    name: mapZ94bEYnZTJMeta?.name ?? "about-map",
    path: mapZ94bEYnZTJMeta?.path ?? "/about/map",
    meta: mapZ94bEYnZTJMeta || {},
    alias: mapZ94bEYnZTJMeta?.alias || [],
    redirect: mapZ94bEYnZTJMeta?.redirect,
    component: () => import("/app/apps/web/src/pages/about/map.vue").then(m => m.default || m)
  },
  {
    name: officerta841nHNpcMeta?.name ?? "about-officer",
    path: officerta841nHNpcMeta?.path ?? "/about/officer",
    meta: officerta841nHNpcMeta || {},
    alias: officerta841nHNpcMeta?.alias || [],
    redirect: officerta841nHNpcMeta?.redirect,
    component: () => import("/app/apps/web/src/pages/about/officer.vue").then(m => m.default || m)
  },
  {
    name: indexuaTi28IIdtMeta?.name ?? "account-menu",
    path: indexuaTi28IIdtMeta?.path ?? "/account/:menu()",
    meta: indexuaTi28IIdtMeta || {},
    alias: indexuaTi28IIdtMeta?.alias || [],
    redirect: indexuaTi28IIdtMeta?.redirect,
    component: () => import("/app/apps/web/src/pages/account/[menu]/index.vue").then(m => m.default || m)
  },
  {
    name: find_45id5nekwt9Lv7Meta?.name ?? "account-find-id",
    path: find_45id5nekwt9Lv7Meta?.path ?? "/account/find-id",
    meta: find_45id5nekwt9Lv7Meta || {},
    alias: find_45id5nekwt9Lv7Meta?.alias || [],
    redirect: find_45id5nekwt9Lv7Meta?.redirect,
    component: () => import("/app/apps/web/src/pages/account/find-id.vue").then(m => m.default || m)
  },
  {
    name: loginqZfjKEwutUMeta?.name ?? "account-login",
    path: loginqZfjKEwutUMeta?.path ?? "/account/login",
    meta: loginqZfjKEwutUMeta || {},
    alias: loginqZfjKEwutUMeta?.alias || [],
    redirect: loginqZfjKEwutUMeta?.redirect,
    component: () => import("/app/apps/web/src/pages/account/login.vue").then(m => m.default || m)
  },
  {
    name: reset_45password0FFhsJPtSbMeta?.name ?? "account-reset-password",
    path: reset_45password0FFhsJPtSbMeta?.path ?? "/account/reset-password",
    meta: reset_45password0FFhsJPtSbMeta || {},
    alias: reset_45password0FFhsJPtSbMeta?.alias || [],
    redirect: reset_45password0FFhsJPtSbMeta?.redirect,
    component: () => import("/app/apps/web/src/pages/account/reset-password.vue").then(m => m.default || m)
  },
  {
    name: indexZp9TuvxS0nMeta?.name ?? "board-board_id",
    path: indexZp9TuvxS0nMeta?.path ?? "/board/:board_id()",
    meta: indexZp9TuvxS0nMeta || {},
    alias: indexZp9TuvxS0nMeta?.alias || [],
    redirect: indexZp9TuvxS0nMeta?.redirect,
    component: () => import("/app/apps/web/src/pages/board/[board_id]/index.vue").then(m => m.default || m)
  },
  {
    name: index5jthpkw0hNMeta?.name ?? "board-board_id-post-post_id",
    path: index5jthpkw0hNMeta?.path ?? "/board/:board_id()/post/:post_id()",
    meta: index5jthpkw0hNMeta || {},
    alias: index5jthpkw0hNMeta?.alias || [],
    redirect: index5jthpkw0hNMeta?.redirect,
    component: () => import("/app/apps/web/src/pages/board/[board_id]/post/[post_id]/index.vue").then(m => m.default || m)
  },
  {
    name: modifycnVBNYFUb8Meta?.name ?? "board-board_id-post-post_id-modify",
    path: modifycnVBNYFUb8Meta?.path ?? "/board/:board_id()/post/:post_id()/modify",
    meta: modifycnVBNYFUb8Meta || {},
    alias: modifycnVBNYFUb8Meta?.alias || [],
    redirect: modifycnVBNYFUb8Meta?.redirect,
    component: () => import("/app/apps/web/src/pages/board/[board_id]/post/[post_id]/modify.vue").then(m => m.default || m)
  },
  {
    name: writebDhutlArMDMeta?.name ?? "board-board_id-write",
    path: writebDhutlArMDMeta?.path ?? "/board/:board_id()/write",
    meta: writebDhutlArMDMeta || {},
    alias: writebDhutlArMDMeta?.alias || [],
    redirect: writebDhutlArMDMeta?.redirect,
    component: () => import("/app/apps/web/src/pages/board/[board_id]/write.vue").then(m => m.default || m)
  },
  {
    name: _91token_934TlhMLrZN8Meta?.name ?? "email-verify-token",
    path: _91token_934TlhMLrZN8Meta?.path ?? "/email/verify/:token()",
    meta: _91token_934TlhMLrZN8Meta || {},
    alias: _91token_934TlhMLrZN8Meta?.alias || [],
    redirect: _91token_934TlhMLrZN8Meta?.redirect,
    component: () => import("/app/apps/web/src/pages/email/verify/[token].vue").then(m => m.default || m)
  },
  {
    name: indexFrDg441YV4Meta?.name ?? "index",
    path: indexFrDg441YV4Meta?.path ?? "/",
    meta: indexFrDg441YV4Meta || {},
    alias: indexFrDg441YV4Meta?.alias || [],
    redirect: indexFrDg441YV4Meta?.redirect,
    component: () => import("/app/apps/web/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91uid_93WPbIUQonPWMeta?.name ?? "notification-uid",
    path: _91uid_93WPbIUQonPWMeta?.path ?? "/notification/:uid()",
    meta: _91uid_93WPbIUQonPWMeta || {},
    alias: _91uid_93WPbIUQonPWMeta?.alias || [],
    redirect: _91uid_93WPbIUQonPWMeta?.redirect,
    component: () => import("/app/apps/web/src/pages/notification/[uid].vue").then(m => m.default || m)
  },
  {
    name: privacyBK7OrfsIN8Meta?.name ?? "privacy",
    path: privacyBK7OrfsIN8Meta?.path ?? "/privacy",
    meta: privacyBK7OrfsIN8Meta || {},
    alias: privacyBK7OrfsIN8Meta?.alias || [],
    redirect: privacyBK7OrfsIN8Meta?.redirect,
    component: () => import("/app/apps/web/src/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: agreeNcP82S87eZMeta?.name ?? "register-agree",
    path: agreeNcP82S87eZMeta?.path ?? "/register/agree",
    meta: agreeNcP82S87eZMeta || {},
    alias: agreeNcP82S87eZMeta?.alias || [],
    redirect: agreeNcP82S87eZMeta?.redirect,
    component: () => import("/app/apps/web/src/pages/register/agree.vue").then(m => m.default || m)
  },
  {
    name: sign_45upTJ5nDt0GbZMeta?.name ?? "register-sign-up",
    path: sign_45upTJ5nDt0GbZMeta?.path ?? "/register/sign-up",
    meta: sign_45upTJ5nDt0GbZMeta || {},
    alias: sign_45upTJ5nDt0GbZMeta?.alias || [],
    redirect: sign_45upTJ5nDt0GbZMeta?.redirect,
    component: () => import("/app/apps/web/src/pages/register/sign-up.vue").then(m => m.default || m)
  },
  {
    name: successrRJMnPK5yHMeta?.name ?? "register-success",
    path: successrRJMnPK5yHMeta?.path ?? "/register/success",
    meta: successrRJMnPK5yHMeta || {},
    alias: successrRJMnPK5yHMeta?.alias || [],
    redirect: successrRJMnPK5yHMeta?.redirect,
    component: () => import("/app/apps/web/src/pages/register/success.vue").then(m => m.default || m)
  }
]