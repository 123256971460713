import "dayjs/locale/ko"; // import locale

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import isBetween from "dayjs/plugin/isBetween";
import isToday from "dayjs/plugin/isToday";
dayjs.extend(customParseFormat);
dayjs.extend(isToday);
dayjs.extend(isBetween);
dayjs.locale("ko");

export { dayjs };
export default defineNuxtPlugin(() => {
  return {
    provide: {
      dayjs,
    },
  };
});
