import type { Router } from "vue-router";

import * as Sentry from "@sentry/vue";
export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig();
  const isProduction = config.public.env === "production";
  Sentry.init({
    app: nuxtApp.vueApp,
    autoSessionTracking: true,
    debug: !isProduction,
    dsn: config.public.sentryDsn,
    enabled: config.public.sentryEnable,
    environment: config.public.env,
    hooks: ["activate", "create", "destroy", "mount", "update"],
    integrations: [
      Sentry.browserTracingIntegration({
        router: nuxtApp.$router as Router,
      }),
      Sentry.replayIntegration({
        maskAllText: false,
        networkDetailAllowUrls: [/^https:\/\/era1980.org\/s-api/],
      }),
    ],
    release:
      config.public.sentryRelease === ""
        ? undefined
        : config.public.sentryRelease,
    replaysOnErrorSampleRate: isProduction ? 0.5 : 1.0,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: isProduction ? 0.5 : 1.0,

    // Capture Replay for 10% of all sessions,
    // We recommend adjusting this value in production
    tracesSampleRate: isProduction ? 0.5 : 1.0,
    trackComponents: true,
  });

  return {
    provide: {
      sentryAddBreadcrumb: Sentry.addBreadcrumb,
      sentryCaptureException: Sentry.captureException,
      sentrySetContext: Sentry.setContext,
      sentrySetTag: Sentry.setTag,
      sentrySetUser: Sentry.setUser,
    },
  };
});
