import type { AxiosInstance } from "axios";

import { Mutex } from "async-mutex";
import axios from "axios";

import type { Command } from "./type/command";

import { SignInCommand } from "./command";
import { RefreshTokenCommand } from "./command/refresh-token";
import { HttpError } from "./exceptions/http.exception";
export class InternalAPIClient {
  private readonly _axios: AxiosInstance;
  private readonly mutex = new Mutex();
  private readonly unauthorizedCallback: (() => void) | null = null;
  public accessToken: null | string = null;
  constructor(url: string, unauthorizedCallback: (() => void) | null = null) {
    this._axios = axios.create({
      baseURL: url,
      withCredentials: true,
    });
    this._axios.interceptors.request.use((config) => {
      if (!config?.headers) {
        throw new Error("헤더를 설정할 수 없습니다.");
      }
      config.headers.Authorization = `bearer ${this.accessToken}`;
      return config;
    });
    this._axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (
          error.config.method === "head" &&
          error.response &&
          error.response.status === 429
        ) {
          error.message =
            "너무 많이 시도하셨습니다. 일정 시간 이후 다시 시도해 주세요.";
          // 401 Unauthorized 에러 처리
        } else if (
          error.config.method === "head" &&
          error.response &&
          error.response.status === 500
        ) {
          error.message = "서버 내부에서 에러가 발생하였습니다.";
          // 500 Internal Server Error 에러 처리
        }
        return Promise.reject(error);
      },
    );
    this.unauthorizedCallback = unauthorizedCallback;
  }
  private async refreshToken() {
    this.accessToken = await new RefreshTokenCommand().execute(this._axios);
  }
  public async login(
    id: string,
    password: string,
    requiredAdminRole = false,
  ): Promise<void> {
    const response = await this.send(
      new SignInCommand({ id, password, requiredAdminRole }),
    );
    this.accessToken = response?.accessToken ?? null;
  }
  public async send<T, K extends Awaited<T>>(
    command: Command<K>,
    headers: Record<string, string> | null = null,
  ): Promise<K> {
    try {
      const res = await command.execute(this._axios, headers);
      return res;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if (
          err.response?.status === 401 &&
          !(command instanceof RefreshTokenCommand)
        ) {
          this.accessToken = null;
          if (this.mutex.isLocked()) {
            await this.mutex.waitForUnlock();
          }
          const success: boolean | null = await this.mutex.runExclusive(
            async () => {
              if (this.accessToken !== null) {
                return null;
              }
              try {
                await this.refreshToken();
                return true;
              } catch (internalErr) {
                return false;
              }
            },
          );
          if (success?.valueOf() === false) {
            if (this.unauthorizedCallback === null) {
              throw new HttpError(401, "로그인이 필요합니다.");
            } else {
              this.unauthorizedCallback();
            }
          }
          try {
            return await command.execute(this._axios, headers);
          } catch (internalErr) {
            if (axios.isAxiosError(internalErr)) {
              throw new HttpError(
                internalErr.response?.status ?? 500,
                internalErr.response?.data.message ?? err.message,
              );
            }
            throw internalErr;
          }
        }
        throw new HttpError(
          err.response?.status ?? 500,
          err.response?.data.message ?? err.message,
        );
      }
      throw err;
    }
  }
  public get existAccessToken(): boolean {
    return !!this.accessToken;
  }
}
