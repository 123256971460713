<script lang="ts" setup>
import { computed } from "vue";

import Circuit from "../misc/Circuit.vue";
type ButtonSize = "large" | "medium" | "small";
interface Props {
  backgroundColor?: string;
  disabled?: boolean;
  label: string;
  loading?: boolean;
  size?: ButtonSize;
  textColor?: string;
}
const props = withDefaults(defineProps<Props>(), {
  backgroundColor: "#007aff",
  disabled: false,
  loading: false,
  size: "medium",
  textColor: "white",
});
const buttonSize = computed(() => `button-size--${props.size}`);

const style = computed(() => ({
  backgroundColor: props.backgroundColor,
  color: props.textColor,
}));
const emits = defineEmits(["click"]);
</script>
<template>
  <button
    :class="[buttonSize, props.loading ? 'button--loading' : '']"
    :disabled="props.disabled"
    :style
    @click="emits('click')"
    class="button"
  >
    <div class="circuit" v-if="props.loading">
      <Circuit />
    </div>
    <div v-else>
      {{ props.label }}
    </div>
  </button>
</template>
<style lang="scss" scoped>
.button--loading,
.button:disabled,
.button[disabled] {
  background-color: #dddfe4 !important;
  color: #1b242b !important;
  cursor: not-allowed;
}
.button {
  border: none;
  border-radius: 7px;
  min-width: 100px;
  cursor: pointer;
  &-size--large {
    font-size: 18px;
    padding: 16px;
  }
  &-size--medium {
    padding: 8px;
  }
  &-size--small {
    width: initial;
    padding: 6px;
  }
}
.button--loading {
  background-color: #43d987 !important;
  border: 3px solid black;
  padding: 4px;
}
</style>
